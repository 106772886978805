exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog-article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-legal-page-tsx": () => import("./../../../src/templates/legal-page.tsx" /* webpackChunkName: "component---src-templates-legal-page-tsx" */),
  "component---src-templates-use-case-tsx": () => import("./../../../src/templates/use-case.tsx" /* webpackChunkName: "component---src-templates-use-case-tsx" */)
}

